const INFO = {
	main: {
		title: "Home",
		name: "Kuanjie (Peter) Chen",
		email: "v2649532831@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		github: "https://github.com/Pterc-code",
		linkedin: "https://www.linkedin.com/in/kuanjie-chen-973a90210/",
		instagram: "https://instagram.com/ptercc?igshid=OGQ5ZDc2ODk2ZA==",
	},

	homepage: {
		title: "An aspiring Software Developer/Data Analyst, and a professional puzzle solver",
		description:
			"I am a skilled individual with proficiency in Python and R, backed by hands-on experience and completed projects. With a strong foundation in programming, I have successfully implemented solutions, conducted data analysis, and developed applications using Python and R. I am eager to further expand my skill set and contribute my expertise to real-world challenges in the fields of data analysis, software development, and beyond.",
	},

	about: {
		title: "I'm Kuanjie Chen, an aspiring data analyst/software developer based in Toronto, Canada.",
		description:
			"I am a dedicated and ambitious student currently pursuing a degree at the University of Toronto. My academic focus revolves around Mathematics, Statistics, and Computer Science. With a passion for problem-solving and data analysis, I thrive in tackling complex challenges and finding innovative solutions. Continuously expanding my knowledge and honing my skills, I am determined to excel in my field of studies.",
	},

	projects: [
		{
			title: "Mecha 02",
			description:
				"The project encompassed utilizing the Discord API to create a sophisticated bot, equipped with powerful functionalities such as text-to-speech and music playback, enhancing the interactive experience within Discord servers.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/Pterc-code/Mecha02.git",
		},

		{
			title: "Reddit Sentiment Analysis",
			description:
				"This project utilized a web scraper with the Reddit API to extract comments from targeted posts. Regression techniques were applied to analyze and determine the sentiment of the posts, providing valuable insights into Reddit discussions and user opinions.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Court Booking Bot",
			description:
				"This project utilized Selenium to create a specialized bot that autonomously books badminton courts based on preconfigured schedules, eliminating the need for manual reservations and streamlining the entire booking process for improved convenience.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "To-Do APP",
			description:
				"The project involved utilizing Kivy framework to develop a task tracker application that enables users to seamlessly add, edit, and delete tasks, providing an efficient and user-friendly solution for managing and organizing daily tasks.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

	],
};

export default INFO;
